import React from "react"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMugHot } from '@fortawesome/free-solid-svg-icons'

 const OnMyMind = () => {
  return (
    <Layout>
      <section className='flex flex-col p-48 h-full justify-center h-full items-center'>
      <FontAwesomeIcon icon={faMugHot} spin className='text-7xl text-blueGray-700 md:text-9xl'/>
      </section>
    </Layout >
  )
}

export default OnMyMind;